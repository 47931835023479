<template>
  <MainLayout class="main-layout--artist-dashboard">
    <AppBreadcrumb
      v-if="breadcrumbData"
      :levels="breadcrumbData"
      class="py-lg">
    </AppBreadcrumb>

    <router-view :user="user"></router-view>
  </MainLayout>
</template>

<script>

import {
  mapState,
  mapActions,
}                         from 'vuex';

import store              from '../../../store/index.js';
import MainLayout         from '../../../components/layout/MainLayout/l-MainLayout.vue';
import AppBreadcrumb      from '../../../components/molecules/AppBreadcrumb/m-AppBreadcrumb.vue';


export default {
  name: 'p-ArtistDashboard',
  components: {
    MainLayout,
    AppBreadcrumb,
  },
  computed: {
    ...mapState('User', ['user']),
    breadcrumbData() {
      return this.$route.meta.breadcrumb?.map(({ label, ...level }) => ({
        ...level,
        label: typeof label === 'function' ? label(store) : label,
      }));
    },
  },
  async created() {
    await this.fetchMe();
  },
  methods: {
    ...mapActions ({
      fetchMe: 'User/FETCH_ME',
    }),
  }
}

</script>
