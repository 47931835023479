export default [
  {
    label: "gendersList.male",
    value: "MALE"
  },
  {
    label: "gendersList.female",
    value: "FEMALE"
  },
];
