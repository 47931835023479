<template>
  <Loader
    v-if="isLoadingData"
    :height="600"
    class="m-auto">
  </Loader>
  <form
    v-else
    @submit.prevent="handleSubmit"
    @input="hasModifiedData = true"
    ref="form"
    novalidate
    class="pb-xl">
    <section>
      <div class="artist-dashboard-settings__section-credentials">
        <form
          v-if="isResettingPassword"
          @submit.prevent="handleResetPassword"
          class="artist-dashboard-settings__edit-password-form"
          ref="reset-password-form"
          novalidate>
          <FieldInput
            v-model="newPassword"
            :id="'new-password'"
            :label="$t('common.password')"
            isAutofocus
            autocomplete="new-password"
            minlength="8"
            required
            type="password"
            class="mb-sm">
          </FieldInput>
          <FieldInput
            v-model="newPasswordConfirmation"
            :id="'new-password-confirmation'"
            :label="$t('common.confirmPassword')"
            minlength="8"
            required
            type="password">
          </FieldInput>
          <div class="artist-dashboard-settings__edit-password-submit-wrapper">
            <Loader
              v-if="isLoadingPassword"
              :height="50"
              class="m-auto">
            </Loader>
            <template v-else>
              <Button
                @click="isResettingPassword = false"
                isWhite
                class="mr-sm"
                type="button">
                {{ $t('common.cancel') }}
              </Button>
              <Button type="submit">
                {{ $t('common.save') }}
              </Button>
            </template>
          </div>
        </form>
        <template v-else>
          <Heading
            :tag="'h3'"
            :level="'h3'"
            class="mb-lg">
            1. {{ $t('settings.identity') }}
          </Heading>
          <fieldset
            name="credentials"
            class="artist-dashboard-settings__credentials">
            <FieldInput
              :id="'email'"
              :value="user.Email"
              :label="$t('common.email')"
              :class="$mediaQueries.isDesktop ? 'mr-lg' : 'mb-sm'"
              readonly>
            </FieldInput>
            <FieldInput
              :id="'password'"
              :value="'*********'"
              :label="$t('common.password')"
              type="password"
              readonly>
            </FieldInput>
          </fieldset>
          <TextLink
            @click="isResettingPassword = true"
            :tag="'button'"
            type="button"
            class="mt-md">
            {{ $t('common.edit') }}
          </TextLink>
        </template>
      </div>

      <hr class="artist-dashboard-settings__separator"/>

      <div class="artist-dashboard-settings__personal-infos">
        <Select
          v-model="genderOption"
          :options="GENDERS_OPTIONS"
          :label="`${$t('common.gender')}*`"
          id="identity-gender-select"
          required
          class="artist-dashboard-settings__gender">
        </Select>
        <FieldInput
          v-model="firstname"
          :id="'firstname'"
          :label="$t('common.firstName')"
          required
          class="artist-dashboard-settings__firstname">
        </FieldInput>
        <FieldInput
        v-model="lastname"
          :id="'lastname'"
          :label="$t('common.lastName')"
          type="lastname"
          required
          class="artist-dashboard-settings__lastname">
        </FieldInput>
        <FieldInput
          v-if="isArtist"
          v-model="nickname"
          :id="'nickname'"
          :label="$t('common.nickname')"
          type="nickname"
          class="artist-dashboard-settings__nickname">
        </FieldInput>
        <fieldset
          name="birthday"
          ref="birthday"
          class="artist-dashboard-settings__birthday-wrapper">
          <label
            for="birthday-input-0"
            class="field-input__label">
            {{ $t('common.birthday') }}*
          </label>
          <FieldInput
            v-for="(birthdayInput, index) in birthdayInputs"
            v-model="birthday[birthdayInput.type]"
            :key="`birthday-input-${index}`"
            :id="`birthday-input-${index}`"
            :placeholder="birthdayInput.placeholder"
            :minNumberValue="birthdayInput.min"
            :maxNumberValue="birthdayInput.max"
            type="tel"
            required>
          </FieldInput>
          <span
            v-if="birthdayErrors.length"
            :class="[
              'field-input__error-message',
              { [`field-input__error-message--${birthdayErrors[0].type}`]: true },
            ]"
            style="grid-column: 1 / -1;">
            {{ birthdayErrors[0].message }}
          </span>
        </fieldset>
        <GoogleLocationFinder
          @input="handleAddressInput"
          @place-selected="handleSetAddress"
          :prefilledText="adress"
          :options="{ types: ['(regions)'] }"
          :label="`${$t('common.address')}*`"
          :placeholder="`${$t('common.address')}*`"
          :errors="googleLocationErrors"
          isRequired
          ref="location-input"
          class="artist-dashboard-settings__address-main">
        </GoogleLocationFinder>
        <FieldInput
          v-model="adressAdditional"
          :label="$t('common.addressComplement')"
          :placeholder="$t('common.addressComplement')"
          type="text"
          id="adress-additional"
          class="artist-dashboard-settings__address-additional">
        </FieldInput>
        <FieldInput
          v-model="city"
          :label="`${$t('common.city')}*`"
          :placeholder="`${$t('common.city')}*`"
          type="text"
          required
          id="city"
          class="artist-dashboard-settings__city">
        </FieldInput>
        <FieldInput
          v-model="postalCode"
          :label="`${$t('common.zipCode')}*`"
          :placeholder="`${$t('common.zipCode')}*`"
          type="text"
          minlength="4"
          maxlength="5"
          pattern="[0-9]{4,5}"
          required
          id="postal-code"
          class="artist-dashboard-settings__postal-code">
        </FieldInput>
        <Select
          v-model="country"
          :options="countriesList"
          :label="`${$t('common.country')}*`"
          :placeholder="`${$t('common.country')}*`"
          required
          id="country"
          class="artist-dashboard-settings__country">
        </Select>
      </div>
    </section>

    <hr class="artist-dashboard-settings__separator"/>

    <section>
      <header class="mb-lg">
        <Heading
          :tag="'h3'"
          :level="'h3'"
          class="mb-lg"
          id="telephone">
          2. {{ $t('artist.phoneValidation.title') }}
        </Heading>
      </header>
      <div class="artist-dashboard-settings__phone-number-wrapper">
        <FieldInput
          v-model="formattedPhoneNumberWithoutPrefix"
          :label="$t('common.mobilePhoneNumber')"
          :placeholder="$t('common.mobilePhoneNumber')"
          :class="{ 'mb-base': !$mediaQueries.isDesktop }"
          hasLeftIcons
          type="tel"
          required
          readonly
          id="phone-number">
          <template #icons-left>
            <span class="artist-dashboard-settings__phone-number-input-prefix">
              {{ phonePrefix }}
            </span>
          </template>
        </FieldInput>
        <Paragraph
          :size="'sm'"
          :class="{ 'mb-base': $mediaQueries.isDesktop }">
          {{ $t('artist.identity.telephoneEdit') }}
        </Paragraph>
        <TextLink
          :tag="'a'"
          href="mailto:contact@bookunartiste.com"
          target="_blank"
          class="mb-base mr-auto">
          {{ $t('artist.dashboard.landing.help.buttonLabel') }}
        </TextLink>
      </div>
    </section>

    <hr
      v-if="isArtist"
      class="artist-dashboard-settings__separator"
    />

    <section
      v-if="isArtist"
      class="artist-dashboard-settings__activity-wrapper">
      <header>
        <Heading
          :tag="'h3'"
          :level="'h3'"
          class="mb-lg"
          id="activity">
          3. {{ $t('artist.activity.title') }}
        </Heading>
      </header>
      <FieldInput
        v-model="activity"
        :label="$t('artist.activity.artisticActivity')"
        :placeholder="$t('artist.activity.artisticActivity')"
        type="text"
        required
        readonly
        id="activity"
        class="artist-dashboard-settings__activity">
      </FieldInput>
      <Checkbox
        v-model="isChildrenAllowed"
        :label="$t('artist.activity.childrenAllowed')"
        :class="$mediaQueries.isDesktop ?  'mb-lg' : 'mb-sm'"
        id="children-permission">
      </Checkbox>
      <TextLink
        :urlPath="'/artist/faq#equipement'"
        target="_blank">
        {{ $t('artist.dashboard.menu.header.editPerformance.tagsArtists.equipement.link') }}
      </TextLink>
    </section>

    <template v-if="hasModifiedData">
      <hr class="artist-dashboard-settings__separator"/>
      <div class="artist-dashboard-settings__submit-wrapper">
        <Loader v-if="isLoading"></Loader>
        <template v-else>
          <Button
            vi
            type="submit"
            class="mr-sm">
            {{ $t('common.save') }}
          </Button>
          <Button
            @click="prefillFields"
            isWhite
            type="button">
            {{ $t('common.cancel') }}
          </Button>
        </template>
      </div>
    </template>

    <Paragraph
      :size="'sm'"
      class="mt-lg">
      *{{ $t('common.mandatoryField') }}
    </Paragraph>
  </form>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
}                                     from 'vuex';

import Loader                         from '../../../components/atoms/Loader/a-Loader.vue';
import Heading                        from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                      from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import TextLink                       from '../../../components/atoms/TextLink/a-TextLink.vue';
import FieldInput                     from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import Button                         from '../../../components/atoms/Button/a-Button.vue';
import Select                         from '../../../components/atoms/Select/a-Select.vue';
import Checkbox                       from '../../../components/atoms/Checkbox/a-Checkbox.vue';
import GoogleLocationFinder           from '../../../components/molecules/GoogleLocationFinder/m-GoogleLocationFinder.vue';
import GENDERS_OPTIONS                from '../../../constants/gender-options.js';
import COUNTRIES_LIST                 from '../../../constants/countriesList.js';
import ACTIVITY_OPTIONS               from '../../../constants/activity-options.js';
import BIRTHDAY_INPUTS                from '../../../constants/birthday-inputs.js';
import {
  getDateFromDDMMYYYYToMMDDYYYY,
  isValidBirthday,
}                                     from '../../../utils/dateUtils.js';


export default {
  name: 'o-ArtistDashboardSettings',
  components: {
    Loader,
    Heading,
    Paragraph,
    TextLink,
    FieldInput,
    Button,
    Select,
    Checkbox,
    GoogleLocationFinder,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    countriesList: COUNTRIES_LIST,
    activityOptions: ACTIVITY_OPTIONS,
    birthdayInputs: BIRTHDAY_INPUTS,
    googleLocationErrors: [],
    birthdayErrors: [],
    isLoading: false,
    isLoadingData: false,
    isLoadingPassword: false,
    isResettingPassword: false,
    hasModifiedData: false,
    newPassword: '',
    newPasswordConfirmation: '',
    hasModifiedAddress: false,
    isChildrenAllowed: false,
    phoneNumber: '',
    activity: '',
    birthday: {
      day: '',
      month: '',
      year: '',
    },
    genderOption: '',
    firstname: '',
    lastname: '',
    nickname: '',
    adress: '',
    adressAdditional: '',
    postalCode: '',
    city: '',
    country: '',
    GENDERS_OPTIONS,
    longitude: '',
    latitude: '',
  }),
  computed: {
    ...mapState('User', ['locale']),
    ...mapGetters({
      isArtist: 'User/IS_ARTIST_USER',
    }),
    formattedPhoneNumberWithoutPrefix() {
      return this.phoneNumber.replace('+33', '');
    },
    phonePrefix() {
      return this.locale.locale === 'fr' ? '+33' : '+44';
    },
    canSave() {
      return this.status !== 'INTERMITTENT' ? this.user.URLKbis : this.user.URLCardID;
    },
  },
  async created() {
    try {
      this.isLoadingData = true;

      await this.fetchMe();

      this.prefillFields();
    } finally {
      this.isLoadingData = false;
    }
  },
  watch: {
    googleLocationErrors(errors = []) {
      if (errors?.length) this.$refs?.['location-input']?.$refs?.search?.$refs?.input.scrollIntoView();
    },
    birthday: {
      deep: true,
      handler() {
        const hasFilledBirthdayInput = Object.values(this.birthday).every(date => date && date !== '0');

        if (!hasFilledBirthdayInput) return;

        const isValidBirthday = this.isValidBirthday({
          day: this.birthday.day,
          month: this.birthday.month,
          year: this.birthday.year,
        });

        if (!isValidBirthday) return this.birthdayErrors.push({ type: 'alert', message: this.$t('artist.identity.errors.invalidBirthday') });
        else this.birthdayErrors = [];
      },
    },
  },
  methods: {
    ...mapMutations({ addErrors: 'ADD_ERRORS' }),
    ...mapActions({
      editIdentity: 'User/EDIT_IDENTITY',
      fetchMe: 'User/FETCH_ME',
      resetPassword: 'User/RESET_PASSWORD',
    }),
    isValidBirthday,
    prefillFields() {
      if (this.user.Birthday) {
        const birthday = new Date(getDateFromDDMMYYYYToMMDDYYYY(this.user.Birthday));

        this.birthday.day = birthday.getDate();
        this.birthday.month = birthday.getMonth() + 1;
        this.birthday.year = birthday.getFullYear();
      }

      this.phoneNumber = this.user?.PhoneNumber ?? '';
      this.genderOption = this.user?.Gender ?? '';
      this.firstname = this.user?.Firstname ?? '';
      this.lastname = this.user?.Lastname ?? '';
      this.nickname = this.user?.ArtisteNickname ?? '';
      this.adress = this.user?.Line1 ?? '';
      this.adressAdditional = this.user?.Line2 ?? '';
      this.postalCode = this.user?.PostalCode ?? '';
      this.city = this.user?.City ?? '';
      this.country = this.user?.Country ?? '';
      this.latitude = this.user?.Latitude ?? '';
      this.longitude = this.user?.Longitude ?? '';

      if (this.isArtist) {
        this.isChildrenAllowed = this.user?.ChildrenPermission === 'CHILDREN_ALLOWED',
        this.activity = this.$t(this.activityOptions.find(({ value }) => value.replaceAll(' ', '').toLowerCase() === this.user?.ActivityInformations.__typename.replaceAll(' ', '').toLowerCase())?.label) || '';
      }

      this.hasModifiedData = false;
    },
    handleAddressInput() {
      this.hasModifiedAddress = true;
      this.latitude = '';
      this.longitude = '';
    },
    handleSetAddress(place) {
      const { address_components = [] } = place;
      const streetNumber = address_components?.find(({ types }) => types.includes('street_number'))?.long_name;
      const adress = address_components?.find(({ types }) => types.includes('route'))?.long_name;
      const postalCode = address_components?.find(({ types }) => types.includes('postal_code'))?.long_name;
      const country = address_components?.find(({ types }) => types.includes('country'))?.short_name;
      const city = address_components?.find(({ types }) => types.includes('locality') || types.includes('postal_town'))?.long_name;

      this.latitude = place.geometry.location.lat();
      this.longitude = place.geometry.location.lng();
      this.adress = adress ? `${streetNumber ? `${streetNumber} ` : ''}${adress}` : '';
      this.city = city ?? '';
      this.postalCode = postalCode ?? '';
      this.country = country ?? '';
      this.googleLocationErrors = [];
      this.hasModifiedAddress = false;
    },
    async handleResetPassword() {
      const isFormValid = this.$refs['reset-password-form'].checkValidity();

      if (!isFormValid) return;

      try {
        this.isLoadingPassword = true;

        const params = this.newPassword;

        await this.resetPassword(params);

        this.isResettingPassword = false;
      } finally {
        this.isLoadingPassword = false;
      }
    },
    async handleSubmit() {
      const isValidForm = this.$refs.form.checkValidity();
      const hasSetupLocationCoordinates = !this.hasModifiedAddress && Boolean(this.latitude) && Boolean(this.longitude);
      const isValidBirthday = this.isValidBirthday({
        day: this.birthday.day,
        month: this.birthday.month,
        year: this.birthday.year
      });

      if (!isValidBirthday) return this.birthdayErrors.push({ type: 'alert', message: this.$t('artist.identity.errors.invalidBirthday') });
      else this.birthdayErrors = [];
      if (!hasSetupLocationCoordinates) return this.googleLocationErrors.push({ type: 'alert', message: this.$t('artist.identity.errors.noCitySelected') });
      else this.googleLocationErrors = [];

      if (!isValidForm) return;

      try {
        this.isLoading = true;

        await this.handleIdentitySubmit();
      } finally {
        this.isLoading = false;
        this.hasModifiedData = false;
      }
    },
    async handleIdentitySubmit() {
      const params = {
        input: {
          Birthday: {
            DOBDay: this.birthday.day,
            DOBMonth: this.birthday.month,
            DOBYear: this.birthday.year,
          },
          Location: {
            Line1: this.adress,
            Line2: this.adressAdditional,
            PostalCode: this.postalCode.toString(),
            City: this.city,
            Country: this.country,
            Longitude: this.longitude,
            Latitude: this.latitude,
          },
          Gender: this.genderOption,
          Firstname: this.firstname,
          Lastname: this.lastname,
          ...(this.isArtist
            ? {
                ChildrenPermission: this.isChildrenAllowed ? 'CHILDREN_ALLOWED' : 'CHILDREN_UNALLOWED',
                ArtisteNickname: this.nickname,
              }
            : {}
          ),
        },
      };

      try {
        this.isLoading = true;

        await this.editIdentity(params);

      } catch (error) {
        this.addErrors([{ type: 'alert', message: this.$t('common.errors.UnknownError') }]);
      } finally {
        this.isLoading = false;
      }
    },
  },
}

</script>

<style lang="scss">

.artist-dashboard-settings {
  &__section-credentials {
    max-width: 100%;

    @media screen and ($desktop) {
      width: 66%;
    }
  }

  &__separator {
    height: 1px;
    margin: var(--space-md) 0;
    border: none;

    @media screen and ($desktop) {
      margin: calc(.75 * var(--space-xl)) 0;
      background-color: var(--color-grey-semi);
    }
  }

  &__credentials {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and ($desktop) {
      flex-direction: row;
    }
  }

  &__edit-password-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__edit-password-submit-wrapper {
    margin-top: var(--space-lg);
    display: flex;
  }

  &__personal-infos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--space-md) var(--space-sm);
    grid-template-areas:
      "gender gender"
      "firstname firstname"
      "lastname lastname"
      "nickname nickname"
      "birthday birthday"
      "address-main address-main"
      "address-additional address-additional"
      "city postal-code"
      "country country"
    ;
    align-items: start;

    @media screen and ($desktop) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, auto);
      grid-template-areas:
        "gender . ."
        "firstname lastname nickname"
        "birthday address-main address-additional"
        "city postal-code country"
      ;
      grid-gap: var(--space-lg);
    }
  }

  &__gender {
    grid-area: gender;
  }

  &__firstname {
    grid-area: firstname;
  }

  &__lastname {
    grid-area: lastname;
  }

  &__nickname {
    grid-area: nickname;
  }

  &__birthday-wrapper {
    grid-area: birthday;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: var(--space-sm);
  }

  &__address-main {
    grid-area: address-main;
  }

  &__address-additional {
    grid-area: address-additional;
  }

  &__city {
    grid-area: city;
  }

  &__postal-code {
    grid-area: postal-code;
  }

  &__country {
    grid-area: country;
  }

  &__phone-number-wrapper {
    display: grid;
    grid-row: auto auto auto;
    grid-row-gap: var(--space-xs);

    @media screen and ($desktop) {
      grid-template-columns: calc(33% - var(--space-lg) / 2) max-content max-content;
      grid-column-gap: var(--space-lg);
      align-items: end;
    }
  }

  &__phone-number-input-prefix {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-right: var(--space-base);
    margin-right: calc(-1 * var(--space-base));
    font-size: var(--text-xs);
    line-height: var(--lineheight-lg);
    color: var(--color-black);
    border-right: 1px solid var(--color-grey-semi);
  }

  &__activity {
    margin-bottom: var(--space-md);

    @media screen and ($desktop) {
      max-width: calc(33% - var(--space-lg) / 2);
    }
  }

  &__status-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-sm);

    @media screen and ($desktop) {
      display: grid;
      grid-template-columns: repeat(3, calc(33% - var(--space-lg)));
      grid-gap: var(--space-lg);
      align-items: start;
    }
  }

  &__activity-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__submit-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--space-base);

    @media screen and ($desktop) {
      flex-direction: row;
      align-items: center;
    }
  }
}

</style>
